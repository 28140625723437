<template>
	<div class="about">
		<h1>Logs</h1>
		
		<a href="#" @click.prevent="pushLogs()" class="btn btn-hero-info">
			<i v-if="onpushlog" class="fa fa-spinner fa-spin"></i>
			Envoyer les logs
		</a>
		<p>Token : {{getToken}} <a href="#" @click.prevent="logout" class="btn btn-hero-info">Reset token</a></p>
		<br>
		<br>
		<a href="#" @click.prevent="resetforms()" class="btn btn-hero-info">Reset forms</a>
		<a href="#" @click.prevent="refreshevents()" class="btn btn-hero-info">Reset events</a>
		<a href="#" @click.prevent="resetinter()" class="btn btn-hero-info">Reset inter</a>
		<a href="#" @click.prevent="removeSyncedPictures()" class="btn btn-hero-info">Reset photos</a>
		
		<p>Nb inter à synchro : {{tosyncsInter.length}}</p>
		
		<p>Nb photo : </p>
		<ul>
			<li v-for="picture in getAll()">
				{{picture.uid}} | {{picture.name}} | {{picture.sync}} <a href="#" class="btn btn-sm btn-danger"
																		 @click.prevent="deletepicture(picture)">X</a>
				<a href="#" class="btn ml-2 btn-sm btn-success" @click.prevent="syncOnePicture(picture)"><i
					class="fa fa-save"></i></a>
			</li>
		</ul>
		<br>
		<p>Inter : </p>
		<ul>
			<li v-for="inter in getAllInter">
				{{inter}}
				<a href="#" class="btn btn-hero-sm btn-hero-danger" @click.prevent="deleteInter(inter.uid)">X</a>
				<a href="#" class="btn btn-hero-sm btn-hero-success" @click.prevent="finishIntervention({uid:inter.uid})"><i
					class="fa fa-save"></i></a>
			</li>
		</ul>
		
		<a href="#" @click.prevent="pushAllIntervention()" class="btn btn-hero-success">Push inter</a>
		<a href="#" @click.prevent="pushAllPictures()" class="btn btn-hero-success">Push pictures</a>
		<br>
		<a href="#" @click.prevent="refreshconfig()" class="btn btn-hero-success">Refresh Config</a>

	</div>
</template>


<script>
	
	import {mapActions, mapGetters} from 'vuex'
	import version from '@/services/version';
	import api from '@/services/api';
	import {Toast} from 'vuex-toast'
	import {ADD_TOAST_MESSAGE} from 'vuex-toast'
	import TransitionPage from '@/components/TransitionPage'
	
	export default {
		name: 'logs',
		data: function () {
			return {
				onpushlog: false
			}
		},
		methods: {
			...mapActions({
				addToast: ADD_TOAST_MESSAGE
			}),
			...mapActions('forms', {'resetforms': 'reset'}),
			...mapActions('events', {'refreshevents': 'refresh'}),
			...mapActions('interventions', {
				'resetinter': 'reset',
				'pushAllIntervention': 'pushAll',
				'finishIntervention': 'finishIntervention',
				'deleteInter': 'delete'
			}),
			...mapActions('pictures', {
				'pushAllPictures': 'sync',
				'deletepicture': 'delete',
				'removeSyncedPicture': 'removeSynced',
				'syncOnePicture': 'syncOne'
			}),
			...mapActions('login', ['logout']),
			...mapActions('config', {'refreshconfig': 'refresh', 'resetRegisterToken': 'resetRegisterToken'}),
			removeSyncedPictures: function(){
				let uid = this.getAllUidEvents;
				this.removeSyncedPicture(uid);
			},
			pushLogs: async function () {
				
				const params = {
					token: this.getToken,
					nbinter: this.tosyncsInter.length,
					pictures: this.getAllPictures(),
					inters: this.getAllInter,
					events: this.getAllEvents(),
					registertoken: this.getRegisterToken,
					version: version.version
				};
				
				try {
					this.onpushlog = true;
					let call = await api.post({url: 'logs', params});
					this.addToast({
						text: 'Logs envoyé au serveur',
						type: 'success',
						dismissAfter: 10000
					});
					this.onpushlog = false;
					
				} catch (err) {
					this.addToast({
						text: 'error pushlog : ' + err,
						type: 'danger',
						dismissAfter: 10000
					});
					this.onpushlog = false;
					console.log('error pushlog', err);
				}
				
			}
		},
		computed: {
			// mix the getters into computed with object spread operator
			...mapGetters('login', [
				'getToken',
			]),
			...mapGetters('events', {'getAllEvents': 'getAll', 'getAllUidEvents': 'getAllUid'}),
			...mapGetters('pictures', {'getAllPictures': 'getAll'}),
			...mapGetters('interventions', {'getAllInter': 'getAll', 'tosyncsInter': 'tosyncs'}),
			...mapGetters('pictures', [
				'getAll',
			]),
			...mapGetters('config', ['getRegisterToken'])
		},
		created() {
			this.$store.dispatch('pictures/loadall');
			
		},
	}
</script>
